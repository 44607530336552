@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.principal-conteudo {
    display: flex;
    justify-content: space-around;
    height: 100vh;
    background-image: url('../../imagens/projeto12.jpg');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
   
}


.div-texto{
    margin-top: 18%;
    margin-left: -35%;
    
 }

.principal-texto{
    font-size: 50px;
    color: rgb(250, 250, 250);
    font-family: Georgia, 'Times New Roman', Times, serif;
   
}

.principal-paragrafo{
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(182, 180, 180);
    font-size: 18px;
    margin-top: 5%;
}




@media screen and (max-width: 768px) {
    .div-texto {
      margin-top: 60%;
      margin-left: 1%;
      
    }

    .principal-texto{
        font-size: 3rem;
    }

    .principal-paragrafo{
        font-size: 1.3rem ;
    }
  }
  


  @media screen and (max-width: 430px) {
    .div-texto {
      margin-top: 80%;
      margin-left: 1%;
      
    }

    .principal-texto{
        font-size: 2rem;
    }

    .principal-paragrafo{
        font-size: 1.2rem ;
    }
  }
