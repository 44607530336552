@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');



.sobre-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110vh; 
    background-image: url('../../imagens/projeto7.jpg');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    
 
  }
    
  
  .paragrafo-sobre {
    font-family: 'Roboto', sans-serif; 
    line-height: 1.5;
    font-size: 20px;
    text-align: left;
    align-items: end;
    color: rgb(255, 255, 255);
    width: 600px;
    margin-top: 15%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.774), rgba(0, 0, 0, 0.849));
    padding: 60px; 
    box-shadow: 0 0 40px rgba(2, 109, 151, 0.377);
    
  }
  


@media only screen and (max-width: 768px) {

 
  .paragrafo-sobre{
    font-size: 1.2rem;
    width: 70%;
    margin-top: 30%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}


@media only screen and (max-width: 430px) {

 
  .paragrafo-sobre{
    font-size: 1rem;
    width: 80%;
    margin-top: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 2.5rem;
  }
}