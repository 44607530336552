.contato-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    color: #fff;
    background-image: url('../../imagens/projeto10.jpg');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    
}

.form-container {
    width: 700px;
    padding: 50px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.541);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    margin-top: 0%;
    margin-left: 2%;
    box-shadow: 0 0 40px rgba(2, 109, 151, 0.377);

}

.icones-countainer{
    margin-right: 90%;
    margin-top: -25%;
    
}


.input-telefone,
.input-nome,
.input-email,
.input-interesse {
    width: 97%;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
   
}

.input-interesse {
    height: 100px;
}

.enviar-btn {
    width: 100%;
    padding: 10px;
    background-color: rgba(100, 99, 99, 0.281);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.enviar-btn:hover {
    background-color: #023e61;
}

.whatsapp-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    font-size: 20px;
}


.whatsapp-content,
.instagram-content,
.facebook-content,
.twitter-content,
.email-content {
   
    color: #d4d4d4;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    margin: 10px;
    text-decoration: none;
    font-size: 25px;
    transition: transform 0.3s ease;
    
}

.whatsapp-content:hover,
.instagram-content:hover,
.facebook-content:hover,
.twitter-content:hover,
.email-content:hover,
.whatsapp-icon:hover {
    transform: scale(1.2);
}


.whatsapp-icon {
    color: rgb(6, 162, 209);
    font-size: 50px;
    transition: transform 0.3s ease;
}




@media only screen and (max-width: 768px) {
    
    .contato-container{
        height: 100vh;
        width: 100%;
    }
    
    .form-container{
      font-size: 14px;
      width: 40rem;
      padding: 50px;
      margin-left: -0.5%;
      margin-top: 10rem;
    }

    .icones-countainer{
        margin-left: 22%;
        margin-top: -21rem;
    }

    .input-telefone,
    .input-nome,
    .input-email,
    .input-interesse {
            width: 90%;
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid #444;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.1);
            color: #fff;
            margin-left: 10%;
        }

    .enviar-btn{
       width: 90%;
       margin-left: 10%;
    }

  }



  @media only screen and (max-width: 430px) {
    
    .contato-container{
        height: 100vh;
        width: 100%;
    }

    .form-container{
        font-size: 14px;
        width: 25rem;
        padding: 50px;
        margin-left: -0.5%;
        margin-top: 1rem;
      }
  
}





@media only screen and (max-width: 390px) {
    
    .contato-container{
        height: 100vh;
        width: 100%;
    }

    .form-container{
        font-size: 14px;
        width: 23rem;
        padding: 50px;
        margin-left: -0.5%;
        margin-top: 1rem;
      }
  

      .icones-countainer{
        margin-left: 21%;
        margin-top: -21rem;
    }
}