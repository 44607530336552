
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



header{
    display: flex;  
    position: fixed;
    height: 15%;
    width: 100%;
    border-bottom: 1px solid rgb(6, 75, 107);
    background-color: rgba(7, 7, 7, 0.438);
}

 a:hover{
    color: rgb(106, 199, 236);
 }

.logo{
    width: 45%;
    margin-top: -11%;  
    margin-left: 60px;
}

.div-pages {
    display: flex;  
    justify-content: flex-end;
    margin-top: 3%;
    margin-left: 30%;
    gap: 50px;
    
}

a{
    color: rgb(253, 253, 255);
    text-decoration: none;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    
}





    @media only screen and (max-width: 768px) {


        .logo{
            margin-top: -4%;
            margin-left: 20%;
        }



        .div-pages {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          width: 40%;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.9);
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 0;
        }
      
        .div-pages.menu-open {
          display: flex;
        }
      
        .menu-icon {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 40px;
          height: 18px;
          cursor: pointer;
          margin-left: 6rem;
          margin-top: 12%;
        }
      
        .menu-icon-bar {
          width: 100%;
          height: 3px;
          background-color: #fff;
          transition: all 0.3s ease;
        }
      
        .menu-icon.open .menu-icon-bar:nth-child(1) {
          transform: translateY(6px) rotate(45deg);
        }
      
        .menu-icon.open .menu-icon-bar:nth-child(2) {
          opacity: 0;
        }
      
        .menu-icon.open .menu-icon-bar:nth-child(3) {
          transform: translateY(-6px) rotate(-45deg);
        }
      }



      @media only screen and (max-width: 430px) {


        .logo{
            margin-top: 0rem;
            margin-left: 15%;
            width: 65%;
        }

        .menu-icon {
         
          width: 40px;
          height: 18px;
          cursor: pointer;
          margin-right: 4rem;
          margin-top: 4rem;
        }

      }